import { useParams } from "react-router-dom";
import { memo, useEffect } from "react";
import { Link } from "@/components/catalyst/link";
import {
  Sidebar,
  SidebarSection,
  SidebarFooter,
} from "@/components/catalyst/sidebar";
import { Text } from "@/components/catalyst/text";
import { SidebarLayout } from "@/components/catalyst/sidebar-layout";
import { Heading, Subheading } from "@/components/catalyst/heading";
import { Badge } from "@/components/catalyst/badge";
import { useSharedWorkerState } from "@/hooks/useSharedWorkerState";
import { parseISO, differenceInSeconds } from "date-fns";
import { datadogRum } from "@datadog/browser-rum";

const MeetingIFrame = memo(function MeetingIFrame(
  props: React.DetailedHTMLProps<
    React.IframeHTMLAttributes<HTMLIFrameElement>,
    HTMLIFrameElement
  >,
) {
  return (
    <iframe
      src={props.src}
      allow="camera; microphone; display-capture; autoplay"
      className="flex-1 h-full w-full min-h-[calc(100vh-6rem)]"
    ></iframe>
  );
});

const stopRUMSession = () => {
  datadogRum.stopSession();
  return false;
};

function Meeting() {
  const { shortId } = useParams();
  const [sharedWorkerState] = useSharedWorkerState();

  useEffect(() => {
    window.addEventListener("beforeunload", stopRUMSession);

    return () => {
      window.removeEventListener("beforeunload", stopRUMSession);
    };
  }, []);

  const shouldShowNoCaption =
    Object.keys(sharedWorkerState.captions).length === 0 &&
    !sharedWorkerState.lastCaptionTime;

  const timeSinceLastCaption = sharedWorkerState.lastCaptionTime
    ? differenceInSeconds(
        Date.now(),
        parseISO(sharedWorkerState.lastCaptionTime),
      )
    : undefined;

  const shouldShowNoRecentCaptionsWarning =
    timeSinceLastCaption && timeSinceLastCaption > 30 && !shouldShowNoCaption;

  let Title = (
    <hgroup>
      <Heading className="mb-1">{""}</Heading>
      <Subheading className="mb-1">{""}</Subheading>
    </hgroup>
  );

  if (sharedWorkerState.meetingTopic && sharedWorkerState.breakoutRoomName) {
    Title = (
      <hgroup>
        <Heading className="mb-1">{sharedWorkerState.breakoutRoomName}</Heading>
        <Subheading className="mb-1">
          {sharedWorkerState.meetingTopic}
        </Subheading>
      </hgroup>
    );
  } else if (sharedWorkerState.meetingTopic) {
    Title = (
      <hgroup>
        <Heading className="mb-1">{sharedWorkerState.meetingTopic}</Heading>
        <Subheading className="mb-1 px-2">{}</Subheading>
      </hgroup>
    );
  }

  if (shortId) {
    return (
      <SidebarLayout
        sidebar={
          <Sidebar className="h-dvh flex">
            <div className="flex-1 overflow-y-scroll">
              <SidebarSection>{Title}</SidebarSection>
              <div>
                <Text>
                  The Connect sidebar is under active development. We will be
                  adding features here throughout the Alpha and Beta testing
                  periods. Have thoughts on what insights or information we
                  should include? Share them with us
                  <Link
                    className="underline"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdCv3CEC9cOOH1cFi2VBBWKdLbaFqg-nvABq6olfPXXcxVpLA/viewform"
                    target="_blank"
                  >
                    here
                  </Link>
                </Text>
              </div>
              <SidebarSection>
                <Heading className="mb-1">
                  Captions
                  {shouldShowNoCaption && (
                    <Badge className="mx-2" color="rose">
                      Warning
                    </Badge>
                  )}
                  {shouldShowNoRecentCaptionsWarning && (
                    <Badge className="mx-2" color="amber">
                      Warning
                    </Badge>
                  )}
                  {!shouldShowNoCaption &&
                    !shouldShowNoRecentCaptionsWarning && (
                      <Badge className="mx-2" color="green">
                        Success
                      </Badge>
                    )}
                </Heading>
              </SidebarSection>
              <div>
                <Text>
                  {shouldShowNoCaption && (
                    <>
                      No captions have synced yet. Please ensure &quot;Show
                      Captions&quot; is enabled
                    </>
                  )}
                  {shouldShowNoRecentCaptionsWarning && (
                    <>
                      It has been over 30 seconds since the last caption was
                      received. Please ensure captions are still enabled.
                    </>
                  )}
                </Text>
              </div>
            </div>
            <SidebarFooter>
              <Text>Version: {import.meta.env.VITE_APP_VERSION_SHORT}</Text>
            </SidebarFooter>
          </Sidebar>
        }
        navbar={<></>}
      >
        <MeetingIFrame src={`/frame/${shortId}`} />
      </SidebarLayout>
    );
  } else {
    return <></>;
  }
}

export default Meeting;
