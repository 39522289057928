import FullScreenPage from "@/components/full-screen-page";
import { Heading, Subheading } from "@/components/catalyst/heading";

function NotFound() {
  return (
    <FullScreenPage>
      <div className="h-screen flex items-center justify-center">
        <div className="flex flex-col justify-center">
          <Heading className="max-w-lg" level={1}>
            404
          </Heading>
          <Subheading className="max-w-lg">
            The page you are looking for does not exist.
          </Subheading>
        </div>
      </div>
    </FullScreenPage>
  );
}

export default NotFound;
