import { ZoomMtg } from "@zoom/meetingsdk";
import { datadogLogs } from "@datadog/browser-logs";

import {
  ZoomBreakoutRoomInfo,
  ZoomCaptionEvent,
  ZoomMeetingInfo,
  ZoomMeetingStatus,
  ZoomNetworkQuality,
  ZoomOnSelfLeave,
  ZoomOnUserAction,
  ZoomParticipantInfo,
} from "./types/events";
import { datadogRum } from "@datadog/browser-rum";

export function preLoadWasm(): void {
  ZoomMtg.preLoadWasm();
}

export function prepareWebSDK(): void {
  ZoomMtg.prepareWebSDK();
}

export function init(leaveUrl: string) {
  return new Promise<void>((resolve, reject) => {
    ZoomMtg.init({
      debug: import.meta.env.MODE === "development",
      disableCallOut: true,
      disableInvite: true,
      disableZoomLogo: true,
      disableJoinAudio: true,
      disableReport: true,
      disableRecord: true,
      disablePreview: true,
      enableWaitingRoomPreview: false,
      disableZoomPhone: true,
      disablePictureInPicture: true,
      disableVoIP: true,
      isSupportNonverbal: false,
      isSupportPolling: false,
      leaveUrl: leaveUrl,
      success: () => {
        resolve();
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
}

export function joinMeeting(
  sdkKey: string,
  meetingId: string,
  signature: string,
  userName: string,
  userEmail: string,
  tk?: string,
  zak?: string,
  passcode?: string,
) {
  return new Promise<void>((resolve, reject) => {
    // Strip "+notetaker@charliehealth.com" from the end of userEmail if present
    const domainToRemove = "+notetaker@charliehealth.com";
    const customerKey = userEmail.endsWith(domainToRemove)
      ? userEmail.slice(0, -domainToRemove.length)
      : userEmail;
    ZoomMtg.join({
      sdkKey: sdkKey,
      signature: signature,
      meetingNumber: meetingId,
      userName: userName,
      userEmail: userEmail,
      tk: tk,
      zak: zak,
      passWord: passcode,
      customerKey: customerKey,
      success: () => {
        resolve();
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
}

export function getCurrentMeetingInfo(): Promise<ZoomMeetingInfo> {
  return new Promise((resolve, reject) => {
    ZoomMtg.getCurrentMeetingInfo({
      success: (data: ZoomMeetingInfo) => {
        resolve(data);
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
}

export function getParticipants(): Promise<ZoomParticipantInfo> {
  return new Promise((resolve, reject) => {
    ZoomMtg.getAttendeeslist({
      success: (data: ZoomParticipantInfo) => {
        resolve(data);
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
}

export function getBreakoutRoomInfo(): Promise<ZoomBreakoutRoomInfo> {
  return new Promise((resolve, reject) => {
    ZoomMtg.getCurrentBreakoutRoom({
      success: (data: ZoomBreakoutRoomInfo) => {
        resolve(data);
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
}

export function listenForCaptions(callback: (data: ZoomCaptionEvent) => void) {
  ZoomMtg.inMeetingServiceListener(
    "onReceiveTranscriptionMsg",
    function (data: ZoomCaptionEvent) {
      callback(data);
    },
  );
}

export function listenForStatusChanges() {
  // Temporary logging to possibly help debug Connect disconnecting issues
  ZoomMtg.inMeetingServiceListener(
    "onMeetingStatus",
    function (data: ZoomMeetingStatus) {
      // {status: 1(connecting), 2(connected), 3(disconnected), 4(reconnecting)}
      datadogLogs.logger.info("onMeetingStatus", data);
      datadogRum.addAction("onMeetingStatus", data);
    },
  );
  ZoomMtg.inMeetingServiceListener(
    "onUserJoin",
    function (data: ZoomOnUserAction) {
      if (data.userName.startsWith("Notetaker for")) {
        datadogLogs.logger.info("onUserJoin", data);
        datadogRum.addAction("onUserJoin", data);
      } else {
        datadogLogs.logger.info("onUserJoin", {
          ...data,
          userName: "Not the Notetaker",
        });
        datadogRum.addAction("onUserJoin", {
          ...data,
          userName: "Not the Notetaker",
        });
      }
    },
  );
  ZoomMtg.inMeetingServiceListener(
    "onUserLeave",
    function (data: ZoomOnUserAction | ZoomOnSelfLeave) {
      // OTHER: 0, // Other reason.
      // HOST_ENDED_MEETING: 1, // Host ended the meeting.
      // SELF_LEAVE_FROM_IN_MEETING: 2, // User (self) left from being in the meeting.
      // SELF_LEAVE_FROM_WAITING_ROOM: 3, // User (self) left from the waiting room.
      // SELF_LEAVE_FROM_WAITING_FOR_HOST_START: 4, // User (self) left from waiting for host to start the meeting.
      // MEETING_TRANSFER: 5, // The meeting was transferred to another end to open.
      // KICK_OUT_FROM_MEETING: 6, // Removed from meeting by host or co-host.
      // KICK_OUT_FROM_WAITING_ROOM: 7, // Removed from waiting room by host or co-host.
      if ("reasonCode" in data) {
        datadogLogs.logger.info("onUserLeave", data);
        datadogRum.addAction("onUserLeave", data);
      } else {
        if (data.userName.startsWith("Notetaker for")) {
          datadogLogs.logger.info("onUserLeave", data);
          datadogRum.addAction("onUserLeave", data);
        } else {
          datadogLogs.logger.info("onUserLeave", {
            ...data,
            userName: "Not the Notetaker",
          });
          datadogRum.addAction("onUserLeave", {
            ...data,
            userName: "Not the Notetaker",
          });
        }
      }
    },
  );
  ZoomMtg.inMeetingServiceListener(
    "onNetworkQualityChange",
    function (data: ZoomNetworkQuality) {
      // {level: 0 || 1 || 2 || 3 || 4 || 5, userId, type: 'uplink' }
      // 0,1 => bad; 2 => normal; 3,4,5 => good;
      datadogLogs.logger.info("onNetworkQualityChange", data);
      datadogRum.addAction("onNetworkQualityChange", data);
    },
  );
}

export function joinBreakoutRoom(breakoutRoomId: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    ZoomMtg.joinBreakoutRoom({
      roomId: breakoutRoomId,
      success: () => {
        resolve();
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
}
