import FullScreenPage from "@/components/full-screen-page";
import { Heading } from "@/components/catalyst/heading";
import { Button } from "@/components/catalyst/button";

function Entry() {
  const url = `${import.meta.env.VITE_CARE_ADMIN_URL}/admin/stacks`;
  return (
    <FullScreenPage>
      <div className="h-screen flex items-center justify-center">
        <div className="flex flex-col justify-center">
          <Heading className="max-w-lg" level={2}>
            To join a meeting via Connect, please click the &quot;Connect&quot;
            button on your session card in MyDot
          </Heading>
          <br />
          <Button className="max-w-36" color="dark/white" href={url}>
            Go to MyDot
          </Button>
        </div>
      </div>
    </FullScreenPage>
  );
}

export default Entry;
