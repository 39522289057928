import FullScreenPage from "@/components/full-screen-page";
import { Heading, Subheading } from "@/components/catalyst/heading";
import { Link } from "@/components/catalyst/link";

function ThankYou() {
  return (
    <FullScreenPage>
      <div className="h-screen flex items-center justify-center">
        <div className="flex flex-col">
          <Heading>Thank you for testing Connect! </Heading>
          <Subheading level={2}>
            Did you experience any issues while using Connect? Report them{" "}
            <Link
              className="underline"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdCv3CEC9cOOH1cFi2VBBWKdLbaFqg-nvABq6olfPXXcxVpLA/viewform"
            >
              here
            </Link>
          </Subheading>
        </div>
      </div>
    </FullScreenPage>
  );
}

export default ThankYou;
