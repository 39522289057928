/**
 * This is defined here so it can be safely imported to other files without
 * triggering code that should only be executed in the worker context
 */

import { SharedWorkerState } from "./types";

export const initialState: SharedWorkerState = {
  jwt: undefined,
  meetingId: undefined,
  meetingUuid: undefined,
  meetingTopic: undefined,
  breakoutRoomId: undefined,
  breakoutRoomName: undefined,
  captions: {},
  participants: {},
  participantUserIdToUuid: {},
  lastCaptionTime: undefined,
};
