import { useState, useEffect } from "react";
import { initialState } from "@/workers/initialState";
import type {
  SharedWorkerState,
  SetTokenMessage,
  SetMeetingInfoMessage,
  SetBreakoutRoomInfoMessage,
  AddCaptionMessage,
  UpdateParticipantListMessage,
  SharedStateWorkerEmission,
  UpdateUserInfoMessage,
} from "@/workers/types";
import type { ZoomCaptionEvent, ZoomParticipant } from "@/zoom/types/events";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { useInterval } from "./useInterval";

const sharedStateWorker = new SharedWorker(
  new URL("@/workers/sharedStateWorker.ts", import.meta.url),
  {
    type: "module",
    name: "CHConnectSharedStateWorker",
  },
);

sharedStateWorker.onerror = (e) => {
  datadogRum.addError(e);
};

type UseSharedWorkerStateReturn = [
  SharedWorkerState,
  {
    setToken: (token: string) => void;
    setMeetingInfo: (id: string, topic: string, uuid: string) => void;
    setUserInfo: (name: string, email: string) => void;
    setBreakoutRoomInfo: (
      id: string | undefined,
      name: string | undefined,
    ) => void;
    addCaption: (caption: ZoomCaptionEvent) => void;
    updateParticipantList: (participants: ZoomParticipant[]) => void;
  },
];

export function useSharedWorkerState(): UseSharedWorkerStateReturn {
  /**
   * This hook registers a sharedStateWorker,
   * ingests and exposes the state stored in that worker,
   * and provides ways to update that state
   */
  const [localState, setLocalState] = useState<SharedWorkerState>(initialState);

  useInterval(() => {
    sharedStateWorker.port.postMessage({
      type: "heartbeat",
    });
  }, 1000);

  useEffect(() => {
    const handleMessage = (e: MessageEvent<SharedStateWorkerEmission>) => {
      switch (e.data.type) {
        case "stateUpdated": {
          setLocalState(e.data.payload);
          datadogLogs.logger.info("Shared state message received", {
            name: e.data.type,
          });
          break;
        }
        case "error": {
          datadogRum.addError(e.data.payload);
          break;
        }
        case "userInfoUpdated": {
          datadogRum.setUser({
            name: e.data.payload.name,
            email: e.data.payload.email,
          });
          break;
        }
      }
    };

    sharedStateWorker.port.addEventListener("message", handleMessage);
    sharedStateWorker.port.start();

    sharedStateWorker.port.postMessage({
      type: "retrieveCurrentState",
    });

    return () => {
      sharedStateWorker.port.removeEventListener("message", handleMessage);
    };
  }, []);

  const setToken = (token: string) => {
    sharedStateWorker.port.postMessage({
      type: "setToken",
      payload: {
        jwt: token,
      },
    } as SetTokenMessage);
    datadogLogs.logger.info("Message sent to the worker", {
      name: "setToken",
    });
  };

  const setMeetingInfo = (id: string, topic: string, uuid: string) => {
    sharedStateWorker.port.postMessage({
      type: "setMeetingInfo",
      payload: {
        meetingId: id,
        meetingTopic: topic,
        meetingUuid: uuid,
      },
    } as SetMeetingInfoMessage);
    datadogLogs.logger.info("Message sent to the worker", {
      name: "setMeetingInfo",
    });
  };

  const setBreakoutRoomInfo = (
    id: string | undefined,
    name: string | undefined,
  ) => {
    sharedStateWorker.port.postMessage({
      type: "setBreakoutRoomInfo",
      payload: {
        breakoutRoomId: id,
        breakoutRoomName: name,
      },
    } as SetBreakoutRoomInfoMessage);
    datadogLogs.logger.info("Message sent to the worker", {
      name: "setBreakoutRoomInfo",
    });
  };

  const addCaption = (caption: ZoomCaptionEvent) => {
    sharedStateWorker.port.postMessage({
      type: "addCaption",
      payload: caption,
    } as AddCaptionMessage);
  };

  const updateParticipantList = (participants: ZoomParticipant[]) => {
    sharedStateWorker.port.postMessage({
      type: "updateParticipantList",
      payload: participants,
    } as UpdateParticipantListMessage);
  };

  const setUserInfo = (name: string, email: string) => {
    sharedStateWorker.port.postMessage({
      type: "updateUserInfo",
      payload: {
        name,
        email,
      },
    } as UpdateUserInfoMessage);
  };

  return [
    localState,
    {
      setToken,
      setMeetingInfo,
      setBreakoutRoomInfo,
      addCaption,
      updateParticipantList,
      setUserInfo,
    },
  ];
}
