const baseEndpoint = `${import.meta.env.VITE_COORDINATION_SERVICE_URL}/api/v1/zoom-signature`;

export interface ZoomSignature {
  signatureId: string;
  meetingId: string;
  signature: string;
  tk: string;
  zak?: string;
  role: number;
  email: string;
  displayName: string;
  passcode?: string;
  jwt: string;
}

export function getZoomSignature(shortId: string): Promise<ZoomSignature> {
  return fetch(`${baseEndpoint}/${shortId}`).then(
    (res) => res.json() as unknown as ZoomSignature,
  );
}
